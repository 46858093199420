import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CircularProgress, Backdrop } from "@mui/material";
import Header from "../LandingPageNew/header";
import PropertyDetails from "../Pages/PropertyDetails";
import NotFound from "../CommonComponents/NotFound";
import ContactBook from "../Pages/ContactBook";
import CreateContactDetails from "../Pages/ContactBook/CreateContactDetails";
import EditContactDetails from "../Pages/ContactBook/EditContactDetails";
import ReviewContactDetails from "../Pages/ContactBook/ReviewContactDetails";
import Staff from "../Pages/Management/Staff";
import AddSingleStaff from "../Pages/Management/Staff/AddSingleStaff";
import AddBatchStaff from "../Pages/Management/Staff/AddBatchStaff";
import EditStaffDetails from "../Pages/Management/Staff/EditStaffDetails";
import ReviewStaffDetails from "../Pages/Management/Staff/ReviewStaffDetails";
import AddResidentSingle from "../Pages/Management/Resident/AddResidentSingle";
import AddBatchResident from "../Pages/Management/Resident/AddResidentBatch";
import EditResidentDetails from "../Pages/Management/Resident/EditResidentDetails";
import ReviewResidentDetails from "../Pages/Management/Resident/ReviewResidentDetails";
import Resident from "../Pages/Management/Resident";
import Visitors from "../Pages/Management/Visitors";
import VisitorsDetails from "../Pages/Management/Visitors/VisitorsDetails";
import { userData } from "../Utils";
import ReviewFaultReport from "../Pages/FaultReport/ReviewFaultReport";
import FaultReport from "../Pages/FaultReport";
import { loadLocalStorageData } from "../Redux/Feature/Login/loginSlice";
import { loadPropertyStatus } from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import ServiceRequest from "../Pages/ServiceRequest/index";
import ReviewService from "../Pages/ServiceRequest/ReviewService";
import FacilitiesBooking from "../Pages/Facilities/Booking";
import ReviewBooking from "../Pages/Facilities/Booking/ReviewBooking";
import FacilityManage from "../Pages/Facilities/Manage";
import CreateFacilityManage from "../Pages/Facilities/Manage/CreateFacilityManage";
import ReviewFacilityManage from "../Pages/Facilities/Manage/ReviewFacilityManage";
import EditFacilityManage from "../Pages/Facilities/Manage/EditFacilityManage";
import ParcelCollection from "../Pages/ParcelCollection/Index";
import ParcelReview from "../Pages/ParcelCollection/ParcelReview";
import Marketing from "../Pages/Marketing";
import CreateEvent from "../Pages/Marketing/Event/CreateEvent";
import EditEvent from "../Pages/Marketing/Event/EditEvent";
import ReviewEvent from "../Pages/Marketing/Event/ReviewEvent";
import CreatePromotion from "../Pages/Marketing/Promotion/CreatePromotion";
import EditPromotion from "../Pages/Marketing/Promotion/EditPromotion";
import ReviewPromotion from "../Pages/Marketing/Promotion/ReviewPromotion";
import Announcement from "../Pages/Announcement";
import CreateAnnouncement from "../Pages/Announcement/CreateAnnouncement";
import EditAnnouncement from "../Pages/Announcement/EditAnnouncement";
import ReviewAnnouncement from "../Pages/Announcement/ReviewAnnouncement";
import Notification from "../Pages/Setting/Notification";
import PropertyDocumentation from "../Pages/Setting/PropertyDocumentation/index.jsx";
import AddDocumentation from "../Pages/Setting/PropertyDocumentation/FAQs/AddDocumentation";
import EditDocumentation from "../Pages/Setting/PropertyDocumentation/FAQs/EditDocumentation";
import AddGuidelines from "../Pages/Setting/PropertyDocumentation/ServiceRequestGuidelines/AddGuidelines";
import EditGuidelines from "../Pages/Setting/PropertyDocumentation/ServiceRequestGuidelines/EditGuidelines";
import ProfileSettings from "../Pages/ProfileSettings/Index";
import EditProfile from "../Pages/ProfileSettings/EditProfile";
import Overview from "../Pages/Overview/Index";
import PropertySettings from "../Pages/Setting/PropertySettings";
import EditGeneralDetails from "../Pages/Setting/PropertySettings/General/EditGeneralDetails";
import EditCommonArea from "../Pages/Setting/PropertySettings/CommonArea/EditCommonArea";
import EditUserRole from "../Pages/Setting/PropertySettings/User/EditUserRole";
import EditAdministrator from "../Pages/Setting/PropertySettings/Administrator/EditAdministrator";
import NotificationView from "../Pages/Notification";
import DeviceControl from "../Pages/Setting/DeviceControl/index.js";
import AddDevice from "../Pages/Setting/DeviceControl/AddDevice.jsx";
import EditDevice from "../Pages/Setting/DeviceControl/EditDevice.jsx";
import Adhoc from "../Pages/Setting/AdHoc/index.js";
import AddAdhoc from "../Pages/Setting/AdHoc/AddAdhoc.jsx";
import EditAdhoc from "../Pages/Setting/AdHoc/EditAdhoc.jsx";

const PropertySuperAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyCompletedStep } = useSelector(
    (state) => state.PropertyDetails
  );

  useEffect(() => {
    dispatch(loadLocalStorageData());
    dispatch(loadPropertyStatus());
  }, [propertyCompletedStep]);

  return (
    <>
      <Routes>
        <Route element={<Header />}>
          {userData("status") === "onboarding" ? (
            <Route path="/propertyDetailsSetup" element={<PropertyDetails />} />
          ) : (
            <>
              <Route path="/contact-book">
                <Route index element={<ContactBook />} />
                <Route
                  path="createContact"
                  element={<CreateContactDetails />}
                />
                <Route
                  path="editContact/:id"
                  element={<EditContactDetails />}
                />
                <Route
                  path="reviewContact/:id"
                  element={<ReviewContactDetails />}
                />
              </Route>
              <Route path="/staffs">
                <Route index element={<Staff />} />
                <Route path="createSingleStaff" element={<AddSingleStaff />} />
                <Route path="createBatchStaff" element={<AddBatchStaff />} />
                <Route path="editStaff/:id" element={<EditStaffDetails />} />
                <Route
                  path="reviewStaff/:id"
                  element={<ReviewStaffDetails />}
                />
              </Route>
              <Route path="/residents">
                <Route index element={<Resident />} />
                <Route
                  path="createSingleResident"
                  element={<AddResidentSingle />}
                />
                <Route
                  path="createBatchResident"
                  element={<AddBatchResident />}
                />
                <Route
                  path="editResident/:id"
                  element={<EditResidentDetails />}
                />
                <Route
                  path="reviewResident/:id"
                  element={<ReviewResidentDetails />}
                />
              </Route>
              <Route path="/Visitors">
                <Route index element={<Visitors />} />
                <Route
                  path="visitorsDetails/:id"
                  element={<VisitorsDetails />}
                />
              </Route>
              <Route path="/fault-reports">
                <Route index element={<FaultReport />} />
                <Route
                  path="reviewFaultReport/:id"
                  element={<ReviewFaultReport />}
                />
              </Route>
              <Route path="/service-request-list">
                <Route index element={<ServiceRequest />} />
                <Route
                  path="/service-request-list/:id"
                  element={<ReviewService />}
                ></Route>
              </Route>
              <Route path="/booking">
                <Route index element={<FacilitiesBooking />}></Route>
                <Route path="/booking/:id" element={<ReviewBooking />}></Route>
              </Route>
              <Route path="/manage">
                <Route index element={<FacilityManage />}></Route>
                <Route
                  path="createFacility"
                  element={<CreateFacilityManage />}
                ></Route>
                <Route
                  path="reviewFacilityManage/:id"
                  element={<ReviewFacilityManage />}
                ></Route>
                <Route
                  path="editFacilityManage/:id"
                  element={<EditFacilityManage />}
                ></Route>
              </Route>
              <Route path="/parcel-collection">
                <Route index element={<ParcelCollection />}></Route>
                <Route
                  path="/parcel-collection/:id"
                  element={<ParcelReview />}
                ></Route>
              </Route>
              <Route path="/marketing">
                <Route index element={<Marketing />}></Route>
                <Route path="createEvent" element={<CreateEvent />} />
                <Route path="editEvent/:id" element={<EditEvent />} />
                <Route path="reviewEvent/:id" element={<ReviewEvent />} />
                <Route path="createPromotion" element={<CreatePromotion />} />
                <Route path="editPromotion/:id" element={<EditPromotion />} />
                <Route
                  path="reviewPromotion/:id"
                  element={<ReviewPromotion />}
                />
              </Route>
              <Route path="/announcement">
                <Route index element={<Announcement />}></Route>
                <Route
                  path="createAnnouncement"
                  element={<CreateAnnouncement />}
                />
                <Route
                  path="editAnnouncement/:id"
                  element={<EditAnnouncement />}
                />
                <Route
                  path="ReviewAnnouncement/:id"
                  element={<ReviewAnnouncement />}
                />
              </Route>
              <Route path="/notifications" element={<Notification />} />
              <Route path="/documentations">
                <Route index element={<PropertyDocumentation />} />
                <Route
                  path="addPropertyDocumentation"
                  element={<AddDocumentation />}
                />
                <Route
                  path="editPropertyDocumentation/:id"
                  element={<EditDocumentation />}
                />
                <Route
                  path="addServiceRequestGuidelines"
                  element={<AddGuidelines />}
                />
                <Route
                  path="editServiceRequestGuidelines"
                  element={<EditGuidelines />}
                />
              </Route>
              <Route path="settings">
                <Route index element={<PropertySettings />} />
                <Route
                  path="editGeneralDetails"
                  element={<EditGeneralDetails />}
                />
                <Route path="editCommonArea" element={<EditCommonArea />} />
                <Route path="EditUserRole" element={<EditUserRole />} />
                <Route
                  path="editAdministrator"
                  element={<EditAdministrator />}
                />
              </Route>
              <Route path="/profile-settings">
                <Route index element={<ProfileSettings />}></Route>
                <Route
                  path="/profile-settings/edit-profile"
                  element={<EditProfile />}
                />
              </Route>
              <Route path="/overview">
                <Route index element={<Overview />}></Route>
              </Route>
              <Route path="/ViewNotification" element={<NotificationView />} />
              <Route path="/device-control">
                <Route index element={<DeviceControl />} />
                <Route path="create-device" element={<AddDevice />} />
                <Route path="edit-device/:id" element={<EditDevice />} />
              </Route>
              <Route path="/adhoc-forms">
                <Route index element={<Adhoc />} />
                <Route path="add-adhoc" element={<AddAdhoc />} />
                <Route path="edit-adhoc/:id" element={<EditAdhoc />} />
              </Route>
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default PropertySuperAdmin;
