import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "firebase/messaging";
import firebase, { auth, messaging } from "../firebase";
import { userData } from "../Utils";
import axiosInstance from "../Redux/AxiosInstance";
import { Box, Button, Typography, Stack, IconButton } from "@mui/material";
import { ReactComponent as LandingLogo } from "../Icons/LandingLogo.svg";

import SignUp from "./signup";
import Otp from "./otp";
import SuccessPage from "./successPage";
import ForgotPassword from "./forgotPassword";
import CreatePassword from "./createPassword";
import Login from "./login";
import { PostLoaderModal } from "../CommonComponents/modal";
import { ErrorTypography } from "../Theme";

const LandingPageNew = () => {
  console.log("new");
  const slug = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let initialState = {
    signup: false,
    createPassword: false,
    otp: false,
    recoverPassword: false,
    successPage: false,
    login: false,
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayScreen, setDisplayScreen] = useState(initialState);
  const [back, setback] = useState("");
  const [propertyDetails, setPropertyDetails] = useState([]);
  const { userDetails } = useSelector((state) => state.Login);
  const [postLoader, setPostLoader] = useState(true);
  const [userNameRole, setUserNameRole] = useState("");
  const [accessDenied, setAccessDefined] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}propertBySlug?slug=${slug["*"]}`)
      .then((res) => {
        setPropertyDetails(res?.data?.data?.properties);
        setPostLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setPostLoader(false);
        setAccessDefined(true);
      });
  }, []);

  const displayScreenHandler = (key) => {
    setDisplayScreen({
      ...initialState,
      [key]: true,
    });
    if (key === "signup" || key === "login") {
      setback(key);
    }
  };

  async function requestPermission() {
    // if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.register("../../public/firebase-messaging-sw");
    // }
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BEcXS_AgaFakKnyow06ZjTswGIwd3YExIYkPBSVH9kjwZ_FSncmeZVEgv_XtlY1s2vHheLW9QC2yk4tSvQ1iGj0",
        });
        if (token) {
          localStorage.setItem("Notify_token", JSON.stringify(token));
        } else {
          console.log("Failed to generate the Notify token.");
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  useEffect(() => {
    localStorage.getItem("accessToken") != null &&
      (userDetails?.user_role == 2 && userData("status") === "onboarding"
        ? navigate("/propertyDetailsSetup")
        : navigate(
          `${location.pathname == "/" ? "overview" : location.pathname}`
        ));

    requestPermission();
  }, []);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {accessDenied ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{ color: "red", fontSize: "24px", fontWeight: "bold" }}
          >
            Access Denied
          </Typography>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            sx={{ marginTop: "20px" }}
          >
            Login
          </Button>
        </Box>
      ) : (
        localStorage.getItem("accessToken") == null &&
        propertyDetails?.map((value) => (
          <>
            {" "}
            <Box
              sx={{
                height: "100%",
                background: `linear-gradient(0deg, #0E4965 0%,rgba(14, 73, 101, 1) 5%, rgba(0, 0, 0, 0.07) 30%), url(${process.env.REACT_APP_FILE_PATH}${value.cover_image})lightgray 50% / cover no-repeat`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: { xs: "50%", sm: "26%" },
                  borderRadius: "8px",
                  padding: "40px",
                  boxShadow:
                    "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
                }}
              >
                {/* <BackLogo /> */}
                {displayScreen.signup ? (
                  <SignUp
                    email={email}
                    setEmail={setEmail}
                    setDisplayScreen={displayScreenHandler}
                    propertyDeatilsProp={propertyDetails}
                  />
                ) : displayScreen.createPassword ? (
                  <CreatePassword
                    email={email}
                    setPasswords={setPassword}
                    setDisplayScreen={displayScreenHandler}
                    setUserNameRole={setUserNameRole}
                  />
                ) : displayScreen.otp ? (
                  <Otp email={email} setDisplayScreen={displayScreenHandler} />
                ) : displayScreen.recoverPassword ? (
                  <ForgotPassword
                    setEmail={setEmail}
                    email={email}
                    setDisplayScreen={displayScreenHandler}
                    back={back}
                  />
                ) : displayScreen.successPage ? (
                  <SuccessPage
                    email={email}
                    password={password}
                    setDisplayScreen={displayScreenHandler}
                    userNameRole={userNameRole}
                  />
                ) : displayScreen.login ? (
                  <Login
                    propertyDeatilsProp={propertyDetails}
                    setDisplayScreen={displayScreenHandler}
                  />
                ) : (
                  <>
                    {/* <StyledPrimaryTypography>Sign up </StyledPrimaryTypography>  
                        <StyledSecondaryTypography>The LOBY sign up portal is for authorised condo administrators.</StyledSecondaryTypography>
                        <TextField value={email} onChange={(e)=>setEmail(e.target.value)}  InputProps={{disableUnderline: true}} sx={{mt:3,mb:2}}  />  
                        <Button onClick={()=>displayScreenHandler('signup')} fullWidth variant="contained" sx={{my:2}}>Sign Up</Button>
                        <StyledTypography sx={{cursor:'pointer'}} onClick={()=>displayScreenHandler('recoverPassword')}  textAlign="center">Forgot password?</StyledTypography> */}
                    <Stack>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "12px",
                          boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                          backgroundImage: `url(${process.env.REACT_APP_FILE_PATH}${value?.property_logo})`,
                          backgroundSize: "cover",
                        }}
                      ></Stack>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#9DA4AE",
                          paddingTop: "34px",
                          lineHeight: "22px",
                        }}
                      >
                        WELCOME TO
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#111927",
                        }}
                      >
                        {value?.title}
                      </Typography>
                      <Typography
                        sx={{
                          maxWidth: "200px",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#6C737F",
                          marginBottom: "30px",
                          marginTop: "13px",
                        }}
                      >
                        {`${value?.address}${" "}${value?.city}${" "}${value?.country
                          }${" "}${value?.zipcode}`}
                        {/* {value?.address}{value?.city}{value?.country}{value?.zipcode} */}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-evenly">
                      <Button
                        onClick={() => displayScreenHandler("login")}
                        fullWidth
                        variant="contained"
                        sx={{ mr: 0.5 }}
                      >
                        Login
                      </Button>
                      <Button
                        onClick={() => displayScreenHandler("signup")}
                        fullWidth
                        sx={{
                          fontWeight: "600",
                          padding: "10px 18px 10px 18px",
                          backgroundColor: "#E7EFF3",
                          color: "#0E5E84",
                          ml: 0.5,
                          "&:hover": {
                            backgroundColor: "#CFDFE6",
                          },
                        }}
                      >
                        Sign Up
                      </Button>
                    </Stack>
                  </>
                )}
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "#D2D6DB",
                    fontSize: "20px",
                  }}
                >
                  <IconButton sx={{ paddingTop: "9px" }}>
                    <LandingLogo />
                  </IconButton>
                  <span
                    style={{
                      fontWeight: 300,
                      fontSize: "14px",
                      marginLeft: "6px",
                    }}
                  >
                    Building Management System
                  </span>
                </Typography>
              </Box>
            </Box>
          </>
        ))
      )}
    </>
  );
};

export default LandingPageNew;
